import axios from "axios";
import { translateObjectKeys } from "@/utils/translation";
import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

const parsePublication = lang => publication => {
  let warning = null;
  if (publication.prevent_publish_confidential) {
    warning = $gettext(
      "A confidential offer (or masked address) cannot be broadcasted on this site."
    );
  }
  if (
    publication.name === "linkedinJobs" &&
    !publication.has_company_linkedin_id
  ) {
    warning = $gettext("LinkedIn ID missing, contact us!");
  }
  if (publication.name === "jobBankCanadaJobs") {
    if (!publication.has_noc_job_title_id) {
      warning = $gettext(
        "You need to provide a National Occupational Classification (NOC) to your offer."
      );
    }
  }
  if (publication.name === "indeed") {
    if (!publication.company_feed_active) {
      warning = $gettext(
        "You need to activate the company's XML feed to post this job on Indeed."
      );
    }
  }
  return {
    ...translateObjectKeys(lang, ["description"])(publication),
    warning
  };
};

const state = () => ({
  jobboards: [],
  publications: [],
  canBeBumped: true
});

const getters = {
  jobboards(state) {
    return state.jobboards;
  },
  publications(state, getters, rootState, rootGetters) {
    return state.publications.map(parsePublication(rootGetters["me/lg"]));
  },
  canBeBumped(state) {
    return state.canBeBumped;
  }
};

const actions = {
  fetchJobboards({ commit }) {
    // @todo introduce the concept of 'static' store with a data version to prevent fetching what we already have.
    return axios
      .get("/jobboards")
      .then(({ data }) => commit("setJobboards", data));
  },
  fetchPublications({ commit }, offerId) {
    return axios.get(`ats/offers/${offerId}/publications`).then(({ data }) => {
      commit("setPublications", data);
    });
  },
  publishOffer({ commit }, { offerId, jobboards }) {
    return axios
      .post(`ats/offers/${offerId}/publish`, { jobboards })
      .then(({ data }) => {
        commit("setPublications", data);
      });
  },
  unpublishOffer({ commit }, { offerId, jobboards }) {
    return axios
      .put(`ats/offers/${offerId}/unpublish`, { jobboards })
      .then(({ data }) => {
        commit("setPublications", data);
      });
  },
  bumpOffer({ commit }, offerId) {
    return axios.put(`ats/offers/${offerId}/bump`).then(() => {
      commit("setCanBeBumped", false);
    });
  }
};

const mutations = {
  setJobboards(state, jobboards) {
    state.jobboards = jobboards;
  },
  setPublications(state, data) {
    state.publications = data.publications || [];
    state.canBeBumped = data.can_be_bumped || false;
  },
  setCanBeBumped(state, value) {
    state.canBeBumped = value;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
